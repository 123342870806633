import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import ContactSection from "../components/contactSection"
import Divider from "../components/divider"

const ImpressumPage = () => (
  <Layout>
    <Seo title="Impressum" />

    <div className="relative shadow-xl sm:overflow-hidden">
      <div className="absolute inset-0">
        <StaticImage src="../images/blake-verdoorn-gM-RfQsZK98-unsplash.jpg"
                     alt="Caffeinity title image coffee beans"
                     transformOptions={{ grayscale: true }}

        />
      </div>
      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
        <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
          <span className="block">Impressum</span>
        </h1>
      </div>
    </div>

    <div className="text-center text-caffeinity-green-dark my-16 max-w-5xl mx-auto relative">

      <div>
        <h1>Legal Notice</h1>
        <h3>offenlegung gemäß § 25 abs.5 mediengesetz, sowie § 5 e-commerce-gesetz (ecg)</h3>
        <h4>Caffeinity e.U.</h4>
        <h4>Dipl.-Ing. Dennis Matthias Binder, MA</h4>
        <h4>A-1120 Wien</h4>
        <h4>Email: caffeinity.vienna@gmail.com</h4>
        <h4>Web: www.caffeinity.at</h4>
        <h4>Rechtsform: Einzelunternehmen</h4>
        <h4>Unternehmensgegenstand, Gewerbe: Handelsgewerbe mit Kaffee, Zubehör und Maschinen</h4>
        <h4>Kammer: Wirtschaftskammer Wien</h4>
        <h4>Bildrechte: Dipl.-Ing. Dennis Matthais Binder, MA; http://unsplash.com</h4>
        <h4>Firmenbuchnummer: FN 567763 X</h4>

        <h3 className="mt-8">Alle Rechte vorbehalten</h3>
        <p>Texte und Grafiken unterliegen dem Schutz des Urheberrechts. Der Inhalt dieser Websites darf nicht zu
          kommerziellen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden.</p>
        <h3 className="mt-8">Keine Garantie, Haftungsausschluss:</h3>
        <p>Diese Website wurde mit größtmöglicher Sorgfalt zusammengestellt, trotzdem kann sich mal ein Fehler
          einschleichen. Jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website
          entstehen, sind ausgeschlossen, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit beruhen.</p>
      </div>

      <Divider />
      <ContactSection />
    </div>


  </Layout>
)

export default ImpressumPage
